// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-test-entry-1-js": () => import("./../../../src/pages/blog/test-entry-1.js" /* webpackChunkName: "component---src-pages-blog-test-entry-1-js" */),
  "component---src-pages-games-game-licenses-js": () => import("./../../../src/pages/games/game-licenses.js" /* webpackChunkName: "component---src-pages-games-game-licenses-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-government-policies-china-js": () => import("./../../../src/pages/government-policies-china.js" /* webpackChunkName: "component---src-pages-government-policies-china-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infographics-js": () => import("./../../../src/pages/infographics.js" /* webpackChunkName: "component---src-pages-infographics-js" */),
  "component---src-pages-market-app-stores-js": () => import("./../../../src/pages/market/app-stores.js" /* webpackChunkName: "component---src-pages-market-app-stores-js" */),
  "component---src-pages-market-apps-js": () => import("./../../../src/pages/market/apps.js" /* webpackChunkName: "component---src-pages-market-apps-js" */),
  "component---src-pages-market-cloud-provider-js": () => import("./../../../src/pages/market/cloud-provider.js" /* webpackChunkName: "component---src-pages-market-cloud-provider-js" */),
  "component---src-pages-market-device-brands-js": () => import("./../../../src/pages/market/device-brands.js" /* webpackChunkName: "component---src-pages-market-device-brands-js" */),
  "component---src-pages-market-game-stores-js": () => import("./../../../src/pages/market/game-stores.js" /* webpackChunkName: "component---src-pages-market-game-stores-js" */),
  "component---src-pages-market-games-js": () => import("./../../../src/pages/market/games.js" /* webpackChunkName: "component---src-pages-market-games-js" */),
  "component---src-pages-market-vr-stores-js": () => import("./../../../src/pages/market/vr-stores.js" /* webpackChunkName: "component---src-pages-market-vr-stores-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-our-company-js": () => import("./../../../src/pages/our-company.js" /* webpackChunkName: "component---src-pages-our-company-js" */),
  "component---src-pages-payments-js": () => import("./../../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-acquisition-js": () => import("./../../../src/pages/services/acquisition.js" /* webpackChunkName: "component---src-pages-services-acquisition-js" */),
  "component---src-pages-services-compliance-js": () => import("./../../../src/pages/services/compliance.js" /* webpackChunkName: "component---src-pages-services-compliance-js" */),
  "component---src-pages-services-distribution-js": () => import("./../../../src/pages/services/distribution.js" /* webpackChunkName: "component---src-pages-services-distribution-js" */),
  "component---src-pages-services-game-publishing-js": () => import("./../../../src/pages/services/game-publishing.js" /* webpackChunkName: "component---src-pages-services-game-publishing-js" */),
  "component---src-pages-services-hosting-js": () => import("./../../../src/pages/services/hosting.js" /* webpackChunkName: "component---src-pages-services-hosting-js" */),
  "component---src-pages-services-integration-js": () => import("./../../../src/pages/services/integration.js" /* webpackChunkName: "component---src-pages-services-integration-js" */),
  "component---src-pages-services-localization-js": () => import("./../../../src/pages/services/localization.js" /* webpackChunkName: "component---src-pages-services-localization-js" */),
  "component---src-pages-services-monetization-js": () => import("./../../../src/pages/services/monetization.js" /* webpackChunkName: "component---src-pages-services-monetization-js" */),
  "component---src-pages-services-other-js": () => import("./../../../src/pages/services/other.js" /* webpackChunkName: "component---src-pages-services-other-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-test-your-site-in-china-js": () => import("./../../../src/pages/test-your-site-in-china.js" /* webpackChunkName: "component---src-pages-test-your-site-in-china-js" */),
  "component---src-templates-infographic-js": () => import("./../../../src/templates/infographic.js" /* webpackChunkName: "component---src-templates-infographic-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

